import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
	
	$('.slider_assets_home').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	})


	$('.block_news__slider').slick({
		adaptiveHeight: true,
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					adaptiveHeight: true,
					slidesToShow: 2,
					slidesToScroll: 2,
					autoplay: true,
					autoplaySpeed: 2000
				}
			},
		{
			breakpoint: 600,
			settings: {
				adaptiveHeight: true,
				slidesToShow: 2,
				slidesToScroll: 2,
				autoplay: true,
				autoplaySpeed: 2000
			}
		},
		{
			breakpoint: 480,
				settings: {
					adaptiveHeight: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 2000
				}
			}
		]
	});	
	// $('.block_courses__slider').slick({
	// 	arrows: false,
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 300,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 4,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1024,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 2
	// 			}
	// 		},
	// 	{
	// 		breakpoint: 600,
	// 		settings: {
	// 			slidesToShow: 2,
	// 			slidesToScroll: 2
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 480,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1
	// 			}
	// 		}
	// 	]
	// });
	
	$('.slider_other_pool').slick({
		arrows: false,
		dots: true
	})
	
	$('.block_process__slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	$('.block_gallery__slider').slick({
		arrows: true,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: $('.prev'),
		nextArrow: $('.next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slider_text_img_slider').slick({
		arrows: true,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.prev_slider_text_img_slider'),
		nextArrow: $('.next_slider_text_img_slider'),
	});

});