import $ from 'jquery';

$(document).ready(function() {
		
	$(window).scroll(function() {
		var sticky = $('header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed'); 
		} else { 
			sticky.removeClass('fixed');
			
		}
	});

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');
	$('.dropdown-submenu > a').on("click", function(e) {
		var dropdown = $(this).parent().find(' > .show');
		$('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
	$(this).next('.dropdown-menu').toggleClass('show');
		e.stopPropagation();
	});
	$('.dropdown').on("hidden.bs.dropdown", function() {
		$('.dropdown-menu.show').removeClass('show');
	});
	
	
	let timeoutID = null;
	
	$('#menu-navigation a[href*=\\#]:not([href=\\#])').click((event) => {
		event.preventDefault();
		const navbar = $('.navbar');
		const menu = event.target.attributes.href.value.replace('#', '');
	
		if (timeoutID !== null)
			clearTimeout(timeoutID);
	
		let submenu = $('.submenu.submenu__' + menu).get(0);
	
		if (submenu && submenu.style && submenu.style.display === 'block') {
			navbar.removeClass('active');
			$(submenu).hide();
		} else {
			$('.submenu:not(.submenu__' + menu + ')').hide();
			navbar.addClass('active');
			$(submenu).show();
		}
	});
	
	$('#menu-navigation a[href*=\\#]:not([href=\\#])').blur((event) => {
		timeoutID = setTimeout(() => {
			$('.submenu').hide();
			$('.navbar').removeClass('active');
			timeoutID = null;
		}, 1000);
	});
});